<script>
import "@/views/Landing/style/variables.css";
import CommonContent from "@/views/Landing/content/common-content.vue";
import BusinessContent from "@/views/Landing/content/business-content.vue";
import DepartmentContent from "@/views/Landing/content/department-content.vue";
import TradingContent from "@/views/Landing/content/trading-content.vue";
import {mapActions} from "vuex";
import {notify} from "@kyvg/vue3-notification";

export default {
  data: () => ({
    state: 'common',
    showScrollTopBtn: false,
    showOfertaModal: false,
    showPoliticModal: false,
    showCompanyModal: false,
  }),
  name: "common-page",
  components: {
    CommonContent,
    BusinessContent,
    DepartmentContent,
    TradingContent,
  },
  beforeMount() {
    if (localStorage.access_token) {
      this.$router.push('/channels');
    }
  },
  mounted() {
    const headerHeight = document.querySelector('.landing header')?.offsetHeight;
    document.documentElement.style.setProperty('--landing-header-height', headerHeight + 'px');

    document.querySelector('.landing main').addEventListener('scroll', (event) => {
      if ((event.target.scrollTop > 500 && !this.showScrollTopBtn) ||
          (event.target.scrollTop < 500 && this.showScrollTopBtn)) {
        this.switchShowScrollTopBtn();
      }
    });
  },
  computed: {
    switchDisplayOfertaModal() {
      return () => {
        this.showOfertaModal = !this.showOfertaModal;
      }
    },
    switchDisplayPoliticModal() {
      return () => {
        this.showPoliticModal = !this.showPoliticModal;
      }
    },
    switchDisplayCompanyModal() {
      return () => {
        this.showCompanyModal = !this.showCompanyModal;
      }
    },
    changeState() {
      return (newState) => {
        document.querySelector('.landing main').scrollTo({
          top: 0,
        });

        setTimeout(() => {
          this.state = newState;
        }, 100);
      }
    },
    switchShowScrollTopBtn() {
      return () => {
        this.showScrollTopBtn = !this.showScrollTopBtn;
      }
    },
  },
  methods: {
    ...mapActions([
      'landingCallback',
    ]),
    sendQuestion(data) {
      const payload = new FormData();
      const phoneToNumber = Number(
          data.phone
              .replace(/-/g, '')
              .replace(/ /g, '')
              .replace('(', '')
              .replace(')', '')
              .replace('+7', '8')
      );

      payload.append('name', data.name);
      payload.append('organization', data.organization || '');
      payload.append('phone', phoneToNumber);

      this.landingCallback(payload);
    },
    scrollToTop() {
      document.querySelector('.landing main').scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    scrollToBottom() {
      document.querySelector(`.landing .${this.state}.contact-form`).scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    },
    copyText(data, title) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        let copyText = 'скопирован';

        if (title === 'Наименование') {
          copyText = 'скопировано';
        }

        navigator.clipboard.writeText(data)
            .then(() => {
              notify({
                type: 'success',
                text: `${title} успешно ${copyText} в буфер обмена!`,
                speed: 1000,
                duration: 5000
              });
            })
            .catch(err => {
              console.error('Could not copy text: ', err);
            });
      }
    },
  },
}
</script>

<template>
  <div class="landing">
    <header>
      <div class="container">
        <img src="@/views/Landing/images/logo.svg" alt="" @click="changeState('common')"/>

        <div class="group">
          <span class="contact" @click="scrollToBottom">Связаться с нами</span>
          <span class="entry" @click="this.$router.push('/authorization')">Войти</span>
        </div>
      </div>
    </header>

    <main>
      <div class="container" v-show="state === 'common'">
        <CommonContent @sendQuestion="sendQuestion" @changeState="changeState"/>
      </div>

      <div class="container" v-show="state === 'business'">
        <BusinessContent @sendQuestion="sendQuestion" @changeState="changeState"/>
      </div>

      <div class="container" v-show="state === 'department'">
        <DepartmentContent @sendQuestion="sendQuestion" @changeState="changeState"/>
      </div>

      <div class="container" v-show="state === 'trading'">
        <TradingContent @sendQuestion="sendQuestion" @changeState="changeState"/>
      </div>

      <button
          v-show="showScrollTopBtn"
          class="go-to-top"
          @click="scrollToTop"
      >
        <img src="@/views/Landing/images/icon/arrow_upward_alt_FILL0_wght400_GRAD0_opsz24.svg" alt=""/>
      </button>
    </main>

    <footer>
      <div class="container">
        <span @click="switchDisplayOfertaModal">Публичная оферта</span>

        <span @click="switchDisplayPoliticModal">Политика конфиденциальности</span>

        <span @click="switchDisplayCompanyModal">Реквизиты компании</span>
      </div>
    </footer>

    <div v-if="showOfertaModal" @click="switchDisplayOfertaModal" class="modal">
      <div class="modal-content" @click.stop="">
        <div class="header">
          <h3>Публичная оферта</h3>
          <span class="close" @click="switchDisplayOfertaModal">&times;</span>
        </div>

        <div>
          <p class="bold">Публичная оферта</p>
          <p>
            Этот документ является официальным предложением интернет-ресурса «Обращайся» (далее по тексту - Исполнитель) заключить договор в соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации (ГК РФ) об оказании услуг. Настоящий договор считается заключенным и приобретает силу с момента совершения оплаты клиентом.
          </p>

          <p class="bold">Определения и термины</p>
          <p>
            — публичное предложение исполнителя, адресованное любому лицу пожелавшему воспользоваться услугами, заключить с ним договор на существующих условиях, в порядке и объёме, изложенных в настоящей оферте, включая все приложения.
            <br />
            — интернет-ресурс «Обращайся» предоставляющая услуги по предоставлению сервиса маркировки интернет-рекламы и доступа к торговой- коммуникационной сети .
            <br />
            — приобретатель услуг, заключивший с исполнителем договор в порядке и на условиях, содержащихся в публичной оферте. Термины Клиент и Заказчик являются равнозначными.
            <br />
            — автоматически или вручную сформированный запрос пользователем, путем заполнения формы, определяющий набор необходимых услуг согласно существующим тарифам. Совершая платеж, вы соглашаетесь полностью и безоговорочно со всеми условиями настоящей Оферты без каких-либо изъятий и ограничений. В частности такой заказ считается "покупкой".
            <br />
            — действия клиента по принятию условий настоящего Договора, акцептовываются путем оплаты, посредством всех имеющихся способов, которые опубликованы и размещены на данном сайте. Принятие условий Договора считается состоявшимся при завершении процесса оплаты за предоставленные услуги.
          </p>

          <p class="bold">1. Предмет Договора</p>
          <p>
            1.1 Исполнитель по выбранному Клиентом тарифу обязуется предоставить доступ к сервису , а Клиент принимает и оплачивает доступ к сервису, в соответствии с условиями настоящего Договора.
            <br />
            1.2. Перечень услуг с указанием их стоимости размещены на сайте.
          </p>

          <p class="bold">2. Права и обязанности сторон</p>
          <p>
            2.1. Обязанности Исполнителя:
            <br />
            2.1.1. Строго соблюдать условия настоящего Договора.
            <br />
            2.1.2. Оказывать услуги , в объеме и в срок, в соответствии с условиями настоящего договора.
            <br />
            2.1.3. Рассматривать претензии Клиентов по качеству оказанных Услуг
            <br />
            2.2. Обязанности Клиента:
            <br />
            2.2.1. Оплатить услуги Исполнителя на условиях настоящего договора.
            <br />
            2.2.2. Соблюдать все требования и рекомендации в полном объеме, размещенные на сайте и в личном кабинете, относящиеся к заказанному виду услуг.
          </p>

          <p class="bold">3. Стоимость услуг и порядок расчетов</p>
          <p>
            3.1. Стоимость услуг по настоящему договору определяется в соответствии с тарифами публикуемыми на сайте.
            <br />
            3.2. В зависимости от выбранного способа оплаты, может взиматься комиссия.
            <br />
            3.3. Оплата оказываемых услуг реализуется клиентом в полном объеме, в момент формирования Заказа или через специальную платёжную ссылку предоставленную Исполнителем.
          </p>

          <p class="bold">4. Ответственность сторон</p>
          <p>
            4.1. Исполнитель обязуется вернуть средства, без учета комиссий, в случае отказа от предоставления заказанных Клиентом услуг.
            <br />
            4.2. Исполнитель не возмещает денежных затрат Клиента за оплаченные услуги, по вине или ошибке Клиента, нарушающие принятые им условия договора (например п.2.1.2).
            <br />
            4.3. Исполнитель не несет ответственности, если услуги не соответствуют субъективным ожиданиям Клиента.
          </p>

          <p class="bold">5. Форс-мажор</p>
          <p>
            5.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему Договору, в случае если это явилось следствием действий обстоятельств непреодолимой силы (пожар, эпидемия, землетрясение, террористический акт, наводнение, ураган, шторм, цунами, оползень, военные действия любого характера, забастовки, в том числе персонала, введение чрезвычайного или военного положения). При этом срок выполнения обязательств по настоящему договору отодвигается соразмерно времени, в течение которого действовали такие обстоятельства.
            Природные изменения, отсутствие снега, низкая/высока температура воздуха, неполадки в работе подъемников и др. неполадки на горнолыжных трассах форс-мажорным обстоятельством не является.
            <br />
            5.2. Сторона, ссылающаяся на эти обстоятельства должна немедленно известить о наступлении их другую сторону и предоставить ей доказательства от уполномоченных органов и служб.
          </p>

          <p class="bold">6. Порядок изменения и расторжения Договора</p>
          <p>
            6.1. Исполнитель оставляет за собой право в любое время вносить изменения в условия настоящего Договора без предварительного уведомления Клиента. Если иное специально не оговорено, все изменения и дополнения к договору вступают в силу с момента опубликования на сайте.
          </p>

          <p class="bold">7. Порядок разрешения споров</p>
          <p>
            7.1. Все споры и разногласия, которые могут возникнуть из настоящего договора стороны решают путем переговоров и с обязательным претензионным порядком. Стороны установили, что письменные претензии подлежат рассмотрению в 30-и дневный срок с момента ее получения.
            <br />
            7.2. В случае невозможности разрешения спора путем переговоров, он передается на рассмотрение в суд.
          </p>

          <p class="bold">8. Срок действия договора. Прочие условия</p>
          <p>
            8.1. Настоящий Договор вступает в силу с момента его заключения (акцепта оферты) и действует до полного исполнения обязательств Сторонами.
            <br />
            8.2. Ни одна из сторон не имеет права передавать третьей стороне свои обязательства по настоящему договору без письменного согласия на то другой стороны.
            <br />
            8.3. В части, не урегулированной настоящим Договором, стороны руководствуются нормами действующего законодательства РФ.
            <br />
            8.4. Все приложения, изменения и дополнения к настоящему Договору должны быть составлены в простой письменной форме и подписаны уполномоченными представителями сторон. С момента их подписания они становятся неотъемлемыми частями настоящего договора.
            <br />
            8.5. Настоящий Договор, включая все приложения к нему, составляет один единый договор заключенный между Исполнителем и Клиентом, который заменяет все другие предварительные соглашения, договоренности и другие отношения, письменные или устные, заключенные между сторонами и имеющие отношение к предмету настоящего Договора.
          </p>
        </div>
      </div>
    </div>

    <div v-if="showPoliticModal" @click="switchDisplayPoliticModal" class="modal">
      <div class="modal-content" @click.stop="">
        <div class="header">
          <h3>Политика в отношении обработки персональных данных</h3>
          <span class="close" @click="switchDisplayPoliticModal">&times;</span>
        </div>

        <div>
          <p class="bold">1. Общие положения</p>
          <p>
            Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» (далее - Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые Общество с ограниченной ответственностью «Сахавизион» (ООО «Сахавизион») (далее – Оператор).
          </p>
          <p>
            1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
          </p>
          <p>
            1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href="https://obrashchaysya.me-org.ru/spd." target="_blank">https://obrashchaysya.me-org.ru/spd.</a>
          </p>

          <p class="bold">2. Основные понятия, используемые в Политике</p>
          <p>
            2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники.
          </p>
          <p>
            2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).
          </p>
          <p>
            2.3. Сайт/Приложение «Обращайся» – мобильное приложение, ресурс, представляющий собой совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://obrashchaysya.me-org.ru/spd" target="_blank">https://obrashchaysya.me-org.ru/spd</a>.
          </p>
          <p>
            2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.
          </p>
          <p>
            2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.
          </p>
          <p>
            2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
          </p>
          <p>
            2.7. Оператор – Общество с ограниченной ответственностью «Сахавизион» (ООО «Сахавизион») ОГРН 1211400007301, ИНН 1427011609, юр.адрес: г. Якутск, ул. Орджоникидзе, д. 36/1, каб. 409, а также партнеры ООО «Сахавизион» - государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
          </p>
          <p>
            2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта <a href="https://obrashaysya.ru" target="_blank">https://obrashaysya.ru</a>.
          </p>
          <p>
            2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, - персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее - персональные данные, разрешенные для распространения).
          </p>
          <p>
            2.10. Пользователь – любой зарегистрированный посетитель Сайта/Приложения <a href="https://obrashchaysya.me-org.ru/spd" target="_blank">https://obrashchaysya.me-org.ru/spd</a>.
          </p>
          <p>
            2.11. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.
          </p>
          <p>
            2.12. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.
          </p>
          <p>
            2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.
          </p>
          <p>
            2.14. Партнер Администрации (Партнер) - компании, осуществляющие самостоятельное предоставление услуг и сервисов на Площадках Администрации на основании заключенных между Партнером Администрации и Администрацией договоров, указанные в настоящем пункте и осуществляющие свою деятельность на основании самостоятельных клиентских соглашений с Пользователями.
          </p>

          <p class="bold">3. Основные права и обязанности Оператора</p>
          <p>
            3.1. Оператор имеет право:
          </p>
          <p>
            – получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;
          </p>
          <p>
            – в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных;
          </p>
          <p>
            – самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами.
          </p>
          <p>
            3.2. Оператор обязан:
          </p>
          <p>
            – предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных данных в течении 10 дней с даты получения такого запроса;
          </p>
          <p>
            – организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;
          </p>
          <p>
            – отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных;
          </p>
          <p>
            – сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 30 дней с даты получения такого запроса;
          </p>
          <p>
            – публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки персональных данных;
          </p>
          <p>
            – принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;
          </p>
          <p>
            – прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;
          </p>
          <p>
            – исполнять иные обязанности, предусмотренные Законом о персональных данных.
          </p>

          <p class="bold">4. Основные права и обязанности субъектов персональных данных</p>
          <p>
            4.1. Пользователь принимает условия Политики и дает Оператору информированное и осознанное согласие на обработку своих персональных данных на условиях, предусмотренных Политикой и Законом:
          </p>
          <p>
            4.1.1. При регистрации и идентификации на Сайте/Приложении — персональные данные, которые Пользователь предоставляет Оператору: путем заполнения формы для регистрации, расположенной в сети Интернет по адресу <a href="https://obrashchaysya.me-org.ru/spd" target="_blank">https://obrashchaysya.me-org.ru/spd</a>. Пользователь считается предоставившим согласие на обработку своих персональных данных в момент нажатия кнопки «Зарегистрироваться». Зарегистрироваться может физическое лицо, проживающее на территории Российской Федерации и достигшее 12 лет. При регистрации согласие на обработку персональных данных за несовершеннолетнего дает его законный представитель.
          </p>
          <p>
            4.1.2. При внесении или изменении персональных данных в разделе «Персональная информация» Личного кабинета — при редактировании или дополнении информации в разделе «Персональная информация». По желанию Пользователя в Личном кабинете им могут быть указаны следующие сведения: дата рождения; пол; изображение (фотография, загруженная через Сайт/Приложение или указана в социальной сети / электронном сервисе); ссылки на профили в социальных сетях. Указанные персональные данные могут использоваться для выполнения Оператором обязательств, предусмотренных Пользовательским соглашением, условиями использования сервисов Сайта/Приложения, и иными договорами. Пользователь считается предоставившим согласие на обработку своих вновь внесенных или измененных персональных данных в момент завершения их редактирования. После заполнения любой из граф, указанных в разделе «Персональная информация», персональные данные обновляются автоматически.
          </p>
          <p>
            4.1.3. При заполнении формы обратной связи, в том числе заявки на прохождения курса, профессии, заявки на использование других сервисов Сайта/Приложения — для персональных данных, которые Пользователь предоставляет Оператору при заполнении формы обратной связи в сети Интернет на Сайте/Приложении и электронных сервисах. Пользователь считается предоставившим согласие на обработку своих персональных данных, внесенных в поля формы обратной связи, в момент нажатия кнопки, подтверждающей отправку заявки (кнопки могут называться «Отправить», «Оставить заявку», «Записаться», «Получить консультацию» и иным аналогичным образом).
          </p>
          <p>
            4.1.4. При оформлении подписки на получение информационных и новостных материалов от Оператора — путем заполнения формы для оформления подписки на рассылку, расположенной в сети Интернет по адресу <a href="https://obrashchaysya.me-org.ru/spd" target="_blank">https://obrashchaysya.me-org.ru/spd</a>. Пользователь считается предоставившим согласие на обработку своих персональных данных при проставлении галочки в поле «Я согласен с условиями обработки персональных данных».
          </p>
          <p>
            4.1.5. При любом использовании Сайта/Приложения — для персональных данных, которые автоматически передаются Оператору в процессе использования Сайта/Приложения, с помощью, установленного на устройстве Пользователя программного обеспечения. Пользователь считается предоставившим согласие на обработку своих персональных данных в момент начала использования Сайта/Приложения.
          </p>
          <p>
            4.1.6. При переводе и получении денежных средств Пользователем — для персональных данных, которые пользователь предоставляет партнеру Оператора при переводе и получении денежных средств через онлайн сервис, доступный на Сайте/в Приложении.
          </p>
          <p>
            4.1.7. При оформлении подписки на получение рекламных материалов от партнеров Оператора — для персональных данных, которые Пользователь предоставляет Оператору при заполнении формы обратной связи в сети Интернет на Сайте/Приложении и электронных сервисах. Пользователь считается предоставившим согласие на обработку своих персональных данных, внесенных в поля формы обратной связи, в целях получения

            рекламных материалов от Оператора и партнеров Оператора в момент проставления Пользователем отметки в чек-боксе “Я согласен с условиями Публичной оферты”, и/или «Я согласен с условиями обработки (персональных данных (ПД)», и/или «Я согласен с Правилами пользования Сайтом/Приложения» (или в чек-боксах аналогичного содержания).
          </p>
          <p>
            4.2. Совершая действия, указанные в п. 4.1 настоящей Политики, Пользователь дает Оператор согласие на обработку соответствующих персональных данных, перечень которых указан в разделе 5 настоящей Политики, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление третьим лицам, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных с использованием и без использования средств автоматизации в соответствии с целями, указанными в разделе 7 настоящей Политики.
          </p>
          <p>
            4.3. Предоставленное Пользователем в соответствии с настоящей Политикой согласие на обработку персональных данных действует со дня предоставления такого согласия и в течение срока, необходимого для достижения целей обработки персональных данных или до момента отзыва Пользователем указанного согласия, если иное не предусмотрено действующим законодательством РФ. Предоставленное Пользователем в соответствии с настоящей Политикой согласие на обработку персональных данных может быть в любой момент отозвано Пользователем. Пользователь может отозвать предоставленное ранее Оператору согласие на обработку персональных данных одним из следующих способов:
          </p>
          <p>
            - направив соответствующее заявление почтой по адресу: 677000, г. Якутск, ул. Орджоникидзе дом 36/1 каб.409;
          </p>
          <p>
            - направив соответствующее заявление в форме электронного документа на адрес электронной почты <a href="mailto:obrashaisya@mail.ru" target="_blank"></a>.
          </p>
          <p>
            4.4. Субъекты персональных данных имеют право:
          </p>
          <p>
            – получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;
          </p>
          <p>
            – требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;
          </p>
          <p>
            – выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;
          </p>
          <p>
            – на отзыв согласия на обработку персональных данных;
          </p>
          <p>
            – обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;
          </p>
          <p>
            – на осуществление иных прав, предусмотренных законодательством РФ.
          </p>
          <p>
            4.5. Субъекты персональных данных обязаны:
          </p>
          <p>
            – предоставлять Оператору достоверные данные о себе;
          </p>
          <p>
            – сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.
          </p>
          <p>
            4.6. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.
          </p>

          <p class="bold">5. Оператор может обрабатывать следующие персональные данные Пользователя</p>
          <p>
            5.1. Фамилия, имя, отчество.
          </p>
          <p>
            5.2. Электронный адрес.
          </p>
          <p>
            5.3. Номера телефонов.
          </p>
          <p>
            5.4. Год, месяц, дата и место рождения.
          </p>
          <p>
            5.5. Фотографии.
          </p>
          <p>
            5.6. Опыт работы, занимаемые должности (если необходимо для исполнения обязательств).
          </p>
          <p>
            5.7. Адрес фактического места проживания и регистрации по месту жительства и (или) по месту пребывания.
          </p>
          <p>
            5.8. Банковские реквизиты (если необходимо для исполнения обязательств).
          </p>
          <p>
            5.9. Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
            <br/>
            5.10. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.
            <br/>
            5.11. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни, Оператором не осуществляется.
            <br/>
            5.12. Обработка персональных данных, разрешенных для распространения, из числа специальных категорий персональных данных, указанных в ч. 1 ст. 10 Закона о персональных данных, допускается, если соблюдаются запреты и условия, предусмотренные ст. 10.1 Закона о персональных данных.
            <br/>
            5.13. Согласие Пользователя на обработку персональных данных, разрешенных для распространения, оформляется отдельно от других согласий на обработку его персональных данных. При этом соблюдаются условия, предусмотренные, в частности, ст. 5.14. Закона о персональных данных. Требования к содержанию такого согласия устанавливаются уполномоченным органом по защите прав субъектов персональных данных.
            <br/>
            5.14.1 Согласие на обработку персональных данных, разрешенных для распространения, Пользователь предоставляет Оператору и его партнерам непосредственно.
            <br/>
            5.14.2 Оператор обязан в срок не позднее трех рабочих дней с момента получения указанного согласия Пользователя опубликовать информацию об условиях обработки, о наличии запретов и условий на обработку неограниченным кругом лиц персональных данных, разрешенных для распространения.
            <br/>
            5.14.3 Передача (распространение, предоставление, доступ) персональных данных, разрешенных субъектом персональных данных для распространения, должна быть прекращена в любое время по требованию субъекта персональных данных. Данное требование должно включать в себя фамилию, имя, отчество (при наличии), контактную информацию (номер телефона, адрес электронной почты или почтовый адрес) субъекта персональных данных, а также перечень персональных данных, обработка которых подлежит прекращению. Указанные в данном требовании персональные данные могут обрабатываться только Оператором, которому оно направлено.
            <br/>
            5.14.4 Согласие на обработку персональных данных, разрешенных для распространения, прекращает свое действие с момента поступления Оператору требования, указанного в п. 5.14.3 настоящей Политики в отношении обработки персональных данных.
          </p>

          <p class="bold">6. Принципы обработки персональных данных</p>
          <p>
            6.1. Обработка персональных данных осуществляется на законной и справедливой основе.
            <br/>
            6.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.
            <br/>
            6.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.
            <br/>
            6.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.
            <br/>
            6.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.
            <br/>
            6.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных или неточных данных.
            <br/>
            6.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.
          </p>

          <p class="bold">7. Цели обработки персональных данных</p>
          <p>
            7.1. Цель обработки персональных данных Пользователя:
            <br/>
            7.1.1. регистрация и идентификация Пользователя на Сайте/Приложении, предоставление Пользователю возможности полноценного использования Сайта/Приложения; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на Сайте/Приложении <a href="http://obrashchaysya.me-org.ru/" target="_blank">http://obrashchaysya.me-org.ru/</a>.
            <br/>
            7.1.2. Отображение профиля Пользователя для иных Пользователей Сайта/Приложения;
            <br/>
            7.1.3. Установление и поддержание связи между Пользователем и Оператором, и его партнерами, устное или письменное консультирование по вопросам оказания услуг и т.д.;
            <br/>
            7.1.4. Заключение и расторжение гражданско-правовых договоров и его исполнение Оператором, его партнерами обязательств перед Пользователем по ним (в частности, Пользовательскому соглашению, договорам оказания услуг, иным договорам). Исполнение обязательств, в частности, включает в себя информирование по услугам (в том числе путем обзвона и направления смссообщений, направления сообщений в мессенджерах «WhatsApp», «Viber», «Telegram», «Vk.com» посредством почтовой рассылки, SMS-сообщений, push-уведомлений, а также сообщение Пользователю такой информации в устном формате по телефону); направление товаров и т.д.;
            <br/>
            7.1.5. Направление Пользователю сообщений рекламного характера, информационных рассылок о продуктах, услугах Оператора и его партнеров, о специальных предложениях, рекламных акциях, розыгрышах, конкурсах, опросах на адрес электронной почты Пользователя, по номеру телефона Пользователя (имени Пользователя) в мессенджерах «WhatsApp», «Viber», «Telegram», «Vk.com» посредством почтовой рассылки, SMS-сообщений, push-уведомлений, а также сообщение Пользователю такой информации в устном формате по телефону;
            <br/>
            7.1.6. Размещение на Сайте/Приложении, в официальных группах социальных сетей и иных сообществах Оператора в сети Интернет, прочих рекламных и информационных источниках, в целях, не связанных с установлением личности Пользователя:
            <br/>
            — видеоматериалов, полученных в процессе оказания услуг,
            <br/>
            — оставленных Пользователем отзывов об услугах, оказываемых на Сайте/Приложении Оператора.
            <br/>
            7.1.7.  Статистические и иные исследования на основе обезличенной информации, предоставленной Пользователем;
            <br/>
            7.1.8. Перевод денежных средств Пользователем через онлайн сервис, доступный на Сайте/в Приложении партнером Оператора;
            <br/>
            7.1.9. Конкретный объем персональных данных, обрабатываемых в указанных выше целях, определен в разделе 5 Политики.
            <br/>
            7.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a href="mailto:obrashaisya@mail.ru" target="_blank">obrashaisya@mail.ru</a> с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».
            <br/>
            7.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.
          </p>

          <p class="bold">8. Правовые основания обработки персональных данных</p>
          <p>
            8.1. Правовыми основаниями обработки персональных данных Оператором являются:
            <br/>
            – уставные (учредительные) документы Оператора;
            <br/>
            – федеральные законы, иные нормативно-правовые акты в сфере защиты персональных данных;
            <br/>
            – согласия Пользователей на обработку их персональных данных, на обработку персональных данных, разрешенных для распространения.
            <br/>
            8.2. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте <a href="https://obrashaysya.ru" target="_blank">https://obrashaysya.ru</a> или направленные Оператору посредством электронной почты. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.
            <br/>
            8.3. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).
            <br/>
            8.4. Субъект персональных данных самостоятельно принимает решение о предоставлении его персональных данных и дает согласие свободно, своей волей и в своем интересе.
          </p>

          <p class="bold">9. Условия обработки персональных данных</p>
          <p>
            9.1. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных.
            <br/>
            9.2. Обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей.
            <br/>
            9.3. Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве.
            <br/>
            9.4. Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем.
            <br/>
            9.5. Обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных.
            <br/>
            9.6. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе (далее – общедоступные персональные данные).
            <br/>
            9.7. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.
            </p>

          <p class="bold">10. Порядок сбора, хранения, передачи и других видов обработки персональных данных</p>
          <p>
            Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.
            <br/>
            10.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
            <br/>
            10.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если субъектом персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения обязательств по гражданско-правовому договору.
            <br/>
            10.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора <a href="obrashaisya@mail.ru" target="_blank">obrashaisya@mail.ru</a> с пометкой «Актуализация персональных данных».
            <br/>
            10.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен договором или действующим законодательством.
            <br/>
            Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a href="mailto:obrashaisya@mail.ru" target="_blank">obrashaisya@mail.ru</a> с пометкой «Отзыв согласия на обработку персональных данных».
            <br/>
            10.5. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами (Операторами) в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Субъект персональных данных и/или Пользователь обязан самостоятельно своевременно ознакомиться с указанными документами. Оператор не несет ответственность за действия третьих лиц, в том числе указанных в настоящем пункте поставщиков услуг.
            <br/>
            10.6. Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для распространения, не действуют в случаях обработки персональных данных в государственных, общественных и иных публичных интересах, определенных законодательством РФ.
            <br/>
            10.7. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.
            <br/>
            10.8. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.
            <br/>
            10.9. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия субъекта персональных данных или отзыв согласия субъектом персональных данных, а также выявление неправомерной обработки персональных данных.
          </p>

          <p class="bold">11. Перечень действий, производимых Оператором с полученными персональными данными</p>
          <p>
            11.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.
            <br/>
            11.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.
          </p>

          <p class="bold">12. Защита персональных данных</p>
          <p>
            12.1. В соответствии с требованиями нормативных документов Оператором создана система защиты персональных данных (СЗПД), состоящая из подсистем правовой, организационной и технической защиты.
            <br/>
            12.2. Подсистема правовой защиты представляет собой комплекс правовых, организационно-распорядительных и нормативных документов, обеспечивающих создание, функционирование и совершенствование СЗПД.
            <br/>
            12.3. Подсистема организационной защиты включает в себя организацию структуры управления СЗПД, разрешительной системы, защиты информации при работе с сотрудниками, партнерами и сторонними лицами.
            <br/>
            12.4. Подсистема технической защиты включает в себя комплекс технических, программных, программно-аппаратных средств, обеспечивающих защиту персональных данных.
            <br/>
            12.4. Основными мерами защиты персональных данных, используемыми Оператором, являются:
            <br/>
            12.5.1. Назначение лица, ответственного за обработку персональных данных, которое осуществляет организацию обработки персональных данных, обучение и инструктаж, внутренний контроль за соблюдением учреждением и его работниками требований к защите персональных данных.
            <br/>
            12.5.2. Определение актуальных угроз безопасности персональных данных при их обработке в ИСПД и разработка мер и мероприятий по защите персональных данных.
            <br/>
            12.5.3. Разработка политики в отношении обработки персональных данных.
            <br/>
            12.5.4. Установление правил доступа к персональным данным, обрабатываемым в ИСПД, а также обеспечение регистрации и учета всех действий, совершаемых с персональными данными в ИСПД.
            <br/>
            12.5.5. Установление индивидуальных паролей доступа сотрудников информационную систему в соответствии с их производственными обязанностями.
            <br/>
            12.5.6. Применение прошедших в установленном порядке процедуру оценки соответствия средств защиты информации.
            <br/>
            12.5.7. Сертифицированное антивирусное программное обеспечение с регулярно обновляемыми базами.
            <br/>
            12.5.8. Соблюдение условий, обеспечивающих сохранность персональных данных и исключающих несанкционированный к ним доступ.
            <br/>
            12.5.9. Обнаружение фактов несанкционированного доступа к персональным данным и принятие мер.
            <br/>
            12.5.10. Восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним.
            <br/>
            12.5.11. Обучение работников Оператора, непосредственно осуществляющих обработку персональных данных, положениям законодательства РФ о персональных данных, в том числе требованиям к защите персональных данных, документам, определяющим политику Оператора в отношении обработки персональных данных, локальным актам по вопросам обработки персональных данных.
            <br/>
            12.5.12. Осуществление внутреннего контроля и аудита.
          </p>

          <p class="bold">13. Актуализация, исправление, удаление и уничтожение персональных данных, ответы на запросы субъектов на доступ к персональным данным</p>
          <p>
            13.1  Актуализация, исправление, удаление и уничтожение персональных данных, ответы на запросы субъектов на доступ к персональным данным
            <br/>
            13.2. Подтверждение факта обработки персональных данных Оператором, правовые основания и цели обработки персональных данных, а также иные сведения, указанные в ч. 7 ст. 14 Закона о персональных данных, предоставляются Оператором субъекту персональных данных или его представителю при обращении либо при получении запроса субъекта персональных данных или его представителя.
            <br/>
            В предоставляемые сведения не включаются персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных.
            <br/>
            Запрос должен содержать:
            <br/>
            - номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе;
            <br/>
            - сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором;
            <br/>
            - подпись субъекта персональных данных или его представителя.
            <br/>
            Запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.
            <br/>
            Если в обращении (запросе) субъекта персональных данных не отражены в соответствии с требованиями Закона о персональных данных все необходимые сведения или субъект не обладает правами доступа к запрашиваемой информации, то ему направляется мотивированный отказ.
            <br/>
            Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с ч. 8 ст. 14 Закона о персональных данных, в том числе если доступ субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц.
            <br/>
            13.2. В случае выявления неточных персональных данных при обращении субъекта персональных данных или его представителя либо по их запросу или по запросу Роскомнадзора Оператор осуществляет блокирование персональных данных, относящихся к этому субъекту персональных данных, с момента такого обращения или получения указанного запроса на период проверки, если блокирование персональных данных не нарушает права и законные интересы субъекта персональных данных или третьих лиц.
            <br/>
            В случае подтверждения факта неточности персональных данных Оператор на основании сведений, представленных субъектом персональных данных или его представителем либо Роскомнадзором, или иных необходимых документов уточняет персональные данные в течение семи рабочих дней со дня представления таких сведений и снимает блокирование персональных данных.
            <br/>
            13.3. В случае выявления неправомерной обработки персональных данных при обращении (запросе) субъекта персональных данных или его представителя либо Роскомнадзора Оператор осуществляет блокирование неправомерно обрабатываемых персональных данных, относящихся к этому субъекту персональных данных, с момента такого обращения или получения запроса.
            <br/>
            13.4. Хранение персональных данных Пользователей осуществляется на электронных носителях. При обработке персональных данных с целью исполнения обязательств по соглашениям с Пользователем Оператор может извлекать персональные данные и хранить их на материальных носителях. Хранение таких персональных данных осуществляется в течение срока, установленного законодательством Российской Федерации об образовании и архивном деле.
            <br/>
            Хранение персональных данных осуществляется (в зависимости от того, какое событие наступит раньше):
            <br/>
            — до момента их удаления Пользователем в разделе «Персональная информация» в личном кабинете на Сайте/Приложении;
            <br/>
            — до момента их уничтожения Оператором — в случае поступления от Пользователя отзыва согласия на обработку персональных данных или требования об уничтожении персональных данных;
            <br/>
            — до момента истечения срока действия согласия (п. 4.3 Политики).
            <br/>
            13.5. Оператор вправе осуществлять передачу персональных данных в соответствии с требованиями законодательства РФ либо согласия субъекта обработки персональных данных третьим лицам, а именно:
            <br/>
            13.5.1. Партнерам, таким как владельцы сайтов и приложений, рекламным сетям и другим
            <br/>
            партнерам, предоставляющие Оператору услуги, связанные с размещением и отображением рекламы на сайтах, в программах, продуктах или сервисах, которые принадлежат таким партнерам или контролируются ими;
            13.5.2. Партнерам, привлекаемых Оператором для оказания услуг Пользователям на платформе Оператора по адресу <a href="http://obrashchaysya.me-org.ru/" target="_blank">http://obrashchaysya.me-org.ru/</a>;
            <br/>
            13.5.3. Партнерам для предоставления Пользователям специализированных предложений о заключении гражданско-правовых договоров;
            <br/>
            13.5.4. Кредитным организациям, участвующим в проведении операцией, в случае использования платежных карт при оплате услуг через платформу Оператора по адресу http://obrashchaysya.me-org.ru/ в целях подтверждения по запросу кредитной организации осуществления таких операций указанными лицами и/или получает от указанных лиц лишь ту персональную информацию Пользователя, которая необходима для указанных целей в соответствии с Политикой;
            <br/>
            13.5.5. Партнерам, привлекаемых Оператором по лицензионному соглашению на платформе Оператора по адресу <a href="https://obrashchaysya.me-org.ru/spd" target="_blank">https://obrashchaysya.me-org.ru/spd</a>;
            <br/>
            13.6. Передача персональных данных третьим лицам, указанным в п. 3.5. Политики осуществляется при соблюдении следующих условий:
            <br/>
            — Третье лицо осуществляет обработку персональных с использованием баз данных на территории Российской Федерации.
            <br/>
            — Третье лицо обеспечивает конфиденциальность персональных данных при их обработке и использовании; обязуется не раскрывать иным лицам, а также не распространять персональные данные Пользователей без их согласия.
            <br/>
            — Третье лицо гарантирует соблюдение следующих мер по обеспечению безопасности при их обработке: персональных данных использование средств защиты информации; обнаружение и фиксация фактов несанкционированного доступа к персональным данным и принятие мер по восстановлению персональных данных; ограничение доступа к персональным данным; регистрация и учет действий с персональными данными; контроль и оценка эффективности применяемых мер по обеспечению безопасности персональных данных.
            <br/>
            — Перечень разрешенных способов обработки персональных данных: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение. Третьему лицу запрещено осуществлять передачу и распространение персональных данных.
          </p>

          <p class="bold">14. Конфиденциальность персональных данных</p>
          <p>
            Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.
          </p>

          <p class="bold">15. Заключительные положения</p>
          <p>
            15.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <a href="mailto:obrashaisya@mail.ru" target="_blank">obrashaisya@mail.ru</a>.
            <br/>
            15.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.
            <br/>
            15.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="http://obrashchaysya.me-org.ru" target="_blank">http://obrashchaysya.me-org.ru</a>.
          </p>
        </div>
      </div>
    </div>

    <div v-if="showCompanyModal" @click="switchDisplayCompanyModal" class="modal">
      <div class="modal-content" @click.stop="" style="width: max-content">
        <div class="header">
          <h3 style="margin-right: 30px;">Реквизиты компании</h3>
          <span class="close" @click="switchDisplayCompanyModal">&times;</span>
        </div>

        <div style="overflow: hidden;">
          <span style="cursor: pointer; display: flex; justify-content: flex-start; align-items: center; flex-wrap: nowrap; gap: 5px; width: 100%;" @click="copyText('ООО Сахавизион', 'Наименование')">
            <span style="font-weight: bold; display: flex; justify-content: flex-end; align-items: center; gap: 5px; width: 150px; margin: 0;">
              Наименование:
            </span>
            ООО Сахавизион
            <i class="material-icons" style="color: black;">content_copy</i>
          </span>

          <span style="cursor: pointer; display: flex; justify-content: flex-start; align-items: center; flex-wrap: nowrap; gap: 5px; width: 100%;" @click="copyText('1211400007301', 'ОГРН')">
            <span style="font-weight: bold; display: flex; justify-content: flex-end; align-items: center; gap: 5px; width: 150px; margin: 0;">
              ОГРН:
            </span>
            1211400007301
            <i class="material-icons" style="color: black;">content_copy</i>
          </span>

          <span style="cursor: pointer; display: flex; justify-content: flex-start; align-items: center; flex-wrap: nowrap; gap: 5px; width: 100%;" @click="copyText('1427011609', 'ИНН')">
            <span style="font-weight: bold; display: flex; justify-content: flex-end; align-items: center; gap: 5px; width: 150px; margin: 0;">
              ИНН:
            </span>
            1427011609
            <i class="material-icons" style="color: black;">content_copy</i>
          </span>

          <span style="cursor: pointer; display: flex; justify-content: flex-start; align-items: center; flex-wrap: nowrap; gap: 5px; width: 100%;" @click="copyText('142701001', 'КПП')">
            <span style="font-weight: bold; display: flex; justify-content: flex-end; align-items: center; gap: 5px; width: 150px; margin: 0;">
              КПП:
            </span>
            142701001
            <i class="material-icons" style="color: black;">content_copy</i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.landing {
  font-family: sans-serif;
  font-size: 16px;

  *:not(i) {
    font-family: "Montserrat", sans-serif !important;
  }

  .container {
    width: 100%;

    .section-wrapper {
      width: 100%;

      .section {
        max-width: 1200px;
        margin: 0 auto;
      }
    }
  }

  header {
    background-color: var(--color-white);
    padding: 20px 0;
    border-bottom: 1px solid var(--landing-color-light-blue);

    .container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        & > * {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          border-radius: 50px;
          font-weight: bold;
          font-size: 0.8em;

          &.contact {
            color: var(--landing-color-dark-blue);

            &:hover {
              background-color: var(--landing-color-dark-blue);
              color: var(--color-white);
            }
          }

          &.entry {
            background-color: var(--landing-color-blue);
            color: var(--color-white);

            &:hover {
              background-color: var(--landing-color-dark-blue);
            }
          }
        }
      }

      img {
        cursor: pointer;
      }
    }
  }

  main {
    background: var(--color-white);
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100dvh - var(--landing-header-height) - 50px);

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--landing-color-light-blue);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--landing-color-blue);
      border-radius: 0;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--landing-color-dark-blue);
    }
  }

  footer {
    background-color: var(--color-white);
    border-top: 1px solid var(--landing-color-light-blue);
    height: 50px;

    .container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      span {
        cursor: pointer;
        color: var(--landing-color-dark-blue);
        font-weight: bold;
      }
    }
  }

  .go-to-top {
    position: fixed;
    bottom: 60px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    background-color: var(--color-blue);
    color: #FFFFFF;
    cursor: pointer;
    z-index: 30;

    img {
      width: 50px;
      height: 50px;
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }
  }

  .modal {
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0;
    width: 100dvw;
    height: 100dvh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 30px;
      border: 1px solid #888;
      min-width: 20%;
      max-width: 80%;
      border-radius: 20px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        & > * {
          font-size: 28px;
          font-weight: bold;
        }

        span {
          cursor: pointer;
        }
      }

      & > div:not(.header) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
        overflow-y: scroll;
        max-height: 80dvh;

        p {
          font-size: 14px;
          text-align: justify;
          padding-right: 5px;

          &.bold {
            font-weight: bold;
            width: 100%;
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .landing {
    header {
      padding: 10px 0;

      .container {
        justify-content: space-evenly;

        img {
          max-width: 150px;
        }

        .group {
          justify-content: flex-start;
          align-items: flex-end;
          flex-direction: column;
          gap: 5px;

          .entry {
            width: 100%;
          }
        }
      }
    }

    main {
      max-height: calc(100dvh - var(--landing-header-height) - 75px);
    }

    footer {
      height: 75px;

      .container {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
      }
    }

    .go-to-top {
      bottom: 80px;
    }

    .container {
      padding: 0 10px;
    }

    .modal {
      .modal-content {
        height: max-content;
        padding: 10px;
        max-width: 95%;

        .header {
          & > * {
            font-size: 20px;
          }

          span {
            font-size: 28px;
          }
        }

        & > div:not(.header) {
          max-height: 90dvh;
        }
      }
    }
  }
}
</style>