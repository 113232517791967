<script>
import "@/views/Landing/style/content.scss";
import NumberAnimation from "vue-number-animation";

export default {
  data: () => ({
    phone_number: null,
    name: null,
    organization: null,
    showDefault: true,
    showCallbackForm: false,
    callbackSuccess: false,
    iAgree: true,
  }),
  emits: [
    'sendQuestion',
    'changeState',
  ],
  name: "trading-content",
  components: {
    NumberAnimation,
  },
  computed: {
    getScrollAnimate() {
      return window.innerWidth <= 1152 ? {} : {enterClass: 'fadein', leaveClass: 'fadeout'};
    },
    rubleFormat() {
      return (number) => {
        return `${number.toFixed(0)} ₽`;
      }
    },
    mlnFormat() {
      return (number) => {
        return `До ${number.toFixed(0)} млн ₽`;
      }
    },
  },
  methods: {
    send(event) {
      this.callbackSuccess = true;

      const target = event.currentTarget;
      const parent = target.closest('.center');
      const form = parent.querySelector('.callback-form');

      form.classList.remove('fadeOpacity');
      form.classList.add('fadeOpacityNone');

      this.$emit('sendQuestion', {phone: this.phone_number, name: this.name, organization: this.organization});

      setTimeout(() => {
        this.showCallbackForm = false;

        const finish = parent.querySelector('.finish-form');

        finish.classList.add('fadeOpacity');
      }, 500);
    },
    openForm(event) {
      this.showCallbackForm = true;

      const target = event.currentTarget;
      const parent = target.closest('.center');
      const common = parent.querySelectorAll('.common-form');

      common.forEach(el => {
        el.classList.add('fadeOpacityNone');
      });

      setTimeout(() => {
        this.showDefault = false;

        const form = parent.querySelector('.callback-form');

        form.classList.add('fadeOpacity');
        form.scrollIntoView(false);
      }, 500);
    },
    handleIntersection(entries) {
      if (entries) {
        this.$refs.animationNumber1.restart();
        // this.$refs.animationNumber2.restart();
        this.$refs.animationNumber3.restart();
        // this.$refs.animationNumber4.restart();
        this.$refs.animationNumber5.restart();
        // this.$refs.animationNumber6.restart();
        this.$refs.animationNumber7.restart();
        // this.$refs.animationNumber8.restart();
      }
    },
    openChannel(channelId) {
      const link = document.createElement('a');

      link.href = `https://api.obrashaysya.ru/show-channel/${channelId}`;
      link.target = '_blank';
      link.click();
      link.remove();
    },
  },
}
</script>

<template>
  <div class="section" v-animateonscroll="getScrollAnimate">
    <div class="column">
      <button @click="$emit('changeState', 'common')" class="go-back-btn">
        <img src="@/views/Landing/images/icon/arrow_back_ios_new.svg" alt=""/>
        на главную
      </button>

      <h2>Торгово-коммуникационная площадка</h2>

      <span>прямых поставок и заказов</span>

      <p>Помогаем компаниям малого и среднего бизнеса быстро выходить на готовую базу заказчиков: бюджетные организации и бизнес</p>

      <div class="group">
        <button @click="this.$router.push('/authorization?register=true')">Зарегистрироваться</button>
      </div>
    </div>

    <img src="@/views/Landing/images/trading.svg" alt=""/>
  </div>

  <div class="section" v-animateonscroll="getScrollAnimate">
    <div class="column">
      <div class="center">
        <div class="card-container mini-text trading">
          <div class="card-item only-text">
            <div class="group-row">
              <span>Единая система</span>
            </div>

            <p>запроса коммерческих предложений от бюджетных организаций и бизнеса</p>

            <div class="number">
              1
            </div>
          </div>

          <div class="card-item only-text">
            <div class="group-row">
              <span>Удобный кабинет</span>
            </div>

            <p>для управления продажами, обмена документами и заключения договоров</p>

            <div class="number">
              2
            </div>
          </div>

          <div class="card-item only-text">
            <div class="group-row">
              <span>Поставки до 800 тыс.</span>
            </div>

            <p>без электронных торгов</p>

            <div class="number">
              3
            </div>
          </div>

          <div class="card-item only-text">
            <div class="group-row">
              <span>Постоянный источник</span>
            </div>

            <p>новых запросов от проверенных заказчиков</p>

            <div class="number">
              4
            </div>
          </div>

          <div class="card-item only-text">
            <div class="group-row">
              <span>Прямые продажи</span>
            </div>

            <p>бюджетным организациям</p>

            <div class="number">
              5
            </div>
          </div>

          <div class="card-item only-text">
            <div class="group-row">
              <span>Надежность</span>
            </div>

            <p>заказчики и поставщики проверены на 100%</p>

            <div class="number">
              6
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section" v-animateonscroll="getScrollAnimate">
    <div class="column">
      <div class="center">
        <h2>Как работает платформа</h2>

        <div class="schema">
          <div class="schema-item">
            <div>
              <span class="text not-animate">Заказчик размещает заявку на любую продукцию</span>
              <p>На размещение требуется всего 1 минута. Можно публиковать неограниченное число заказов</p>
            </div>

            <span class="number line max">1</span>
          </div>

          <div class="schema-item reverse">
            <div>
              <span class="text not-animate">Поставщики предлагают цены</span>
              <p>Мы оповещаем поставщика о новом заказе через личный кабинет, после чего, он предлагает свои цены на продукцию</p>
            </div>

            <span class="number line max">2</span>
          </div>

          <div class="schema-item">
            <div>
              <span class="text not-animate">Заказчик выбирает поставщика</span>
              <p>Из всех полученных предложений заказчик может выбрать любое подходящее ему и связаться с поставщиком</p>
            </div>

            <span class="number line max">3</span>
          </div>

          <div class="schema-item reverse">
            <div>
              <span class="text not-animate">Взаимодействие поставщика и клиента</span>
              <p>Коммуникация, подписание договоров и актов — все в одной системе</p>
            </div>

            <span class="number line max">4</span>
          </div>

          <div class="schema-item">
           <div>
             <span class="text not-animate">Общение с ответственными сотрудниками</span>
             <p>Доступ к непосредственным исполнителям</p>
           </div>

            <span class="number">5</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section" v-animateonscroll="getScrollAnimate">
    <div class="column">
      <div class="center">
        <h2>Наши кейсы</h2>

        <div class="company-card" v-intersect="handleIntersection">
          <div class="company-item">
            <div class="header" @click="openChannel('381')">
              <img src="@/views/Landing/images/company/STOK-AERO_logo_1.svg" alt="" />
            </div>

            <div class="column">
              <span class="gray">СТОК-АЭРО</span>

              <div class="details">
                <p>Сфера</p>
                <span>Услуги проектирования</span>
              </div>
            </div>

            <div class="row">
              <div>
                <p>Средний чек</p>

                <NumberAnimation
                    ref="animationNumber1"
                    :from="0"
                    :to="350000"
                    :duration="2"
                    :format="rubleFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>

              <div v-if="false">
                <p>Число заказов на сумму</p>

                <NumberAnimation
                    ref="animationNumber2"
                    :from="0"
                    :to="35"
                    :duration="2"
                    :format="mlnFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>
            </div>
          </div>

          <div class="company-item">
            <div class="header" @click="openChannel('405')">
              <img src="@/views/Landing/images/company/azbuka.svg" alt="" />
            </div>

            <div class="column">
              <span class="gray">АЗБУКА</span>

              <div class="details">
                <p>Сфера</p>
                <span>Поставка канцтоваров</span>
              </div>
            </div>

            <div class="row">
              <div>
                <p>Средний чек</p>

                <NumberAnimation
                    ref="animationNumber3"
                    :from="0"
                    :to="15000"
                    :duration="2"
                    :format="rubleFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>

              <div v-if="false">
                <p>Число заказов на сумму</p>

                <NumberAnimation
                    ref="animationNumber4"
                    :from="0"
                    :to="12"
                    :duration="2"
                    :format="mlnFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>
            </div>
          </div>

          <div class="company-item">
            <div class="header" @click="openChannel('337')">
              <img src="@/views/Landing/images/company/lion.svg" alt="" />
            </div>

            <div class="column">
              <span class="gray">ПРАЙД</span>

              <div class="details">
                <p>Сфера</p>
                <span>Поставка оборудования</span>
              </div>
            </div>

            <div class="row">
              <div>
                <p>Средний чек</p>

                <NumberAnimation
                    ref="animationNumber5"
                    :from="0"
                    :to="80000"
                    :duration="2"
                    :format="rubleFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>

              <div v-if="false">
                <p>Число заказов на сумму</p>

                <NumberAnimation
                    ref="animationNumber6"
                    :from="0"
                    :to="20"
                    :duration="2"
                    :format="mlnFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>
            </div>
          </div>

          <div class="company-item">
            <div class="header" @click="openChannel('402')">
              <img src="@/views/Landing/images/company/tent.svg" alt="" />
            </div>

            <div class="column">
              <span class="gray">ХОТУ ТЕНТ</span>

              <div class="details">
                <p>Сфера</p>
                <span>Производство покрытий и спецодежды</span>
              </div>
            </div>

            <div class="row">
              <div>
                <p>Средний чек</p>

                <NumberAnimation
                    ref="animationNumber7"
                    :from="0"
                    :to="80000"
                    :duration="2"
                    :format="rubleFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>

              <div v-if="false">
                <p>Число заказов на сумму</p>

                <NumberAnimation
                    ref="animationNumber8"
                    :from="0"
                    :to="50"
                    :duration="2"
                    :format="mlnFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section" v-animateonscroll="getScrollAnimate">
    <div class="column">
      <div class="center">
        <img class="micro" src="@/views/Landing/images/big-icon/government_2.svg" alt="" />

        <div class="group-row group-center">
          <h2>С нами работает более 300</h2>
        </div>

        <span class="align-center">бюджетных организаций Республики Саха (Якутия)</span>

        <div class="items-wrap">
          <div class="items marquee">
            <div class="item" @click="openChannel('462')">
              <img src="@/views/Landing/images/company/MKU.svg" alt="" />

              <span>Управа Сайсарского округа" МКУ ГО "город Якутск"</span>
            </div>

            <div class="item" @click="openChannel('559')">
              <img src="@/views/Landing/images/company/gos_tr.svg" alt="" />

              <span>Государственный театр эстрады РС(Я)</span>
            </div>

            <div class="item" @click="openChannel('172')">
              <img src="@/views/Landing/images/company/saha_tr.svg" alt="" />

              <span>Саха Театра</span>
            </div>

            <div class="item" @click="openChannel('441')">
              <img src="@/views/Landing/images/company/CIT.svg" alt="" />

              <span>МКУ ЦИТ</span>
            </div>

            <div class="item" @click="openChannel('443')">
              <img src="@/views/Landing/images/company/kid_nas.svg" alt="" />

              <span>Администрация Тулагино-Кильдямского наслега</span>
            </div>
          </div>

          <div class="items marquee" aria-hidden="true">
            <div class="item" @click="openChannel('462')">
              <img src="@/views/Landing/images/company/MKU.svg" alt="" />

              <span>Управа Сайсарского округа" МКУ ГО "город Якутск"</span>
            </div>

            <div class="item" @click="openChannel('559')">
              <img src="@/views/Landing/images/company/gos_tr.svg" alt="" />

              <span>Государственный театр эстрады РС(Я)</span>
            </div>

            <div class="item" @click="openChannel('172')">
              <img src="@/views/Landing/images/company/saha_tr.svg" alt="" />

              <span>Саха Театра</span>
            </div>

            <div class="item" @click="openChannel('441')">
              <img src="@/views/Landing/images/company/CIT.svg" alt="" />

              <span>МКУ ЦИТ</span>
            </div>

            <div class="item" @click="openChannel('443')">
              <img src="@/views/Landing/images/company/kid_nas.svg" alt="" />

              <span>Администрация Тулагино-Кильдямского наслега</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section" v-animateonscroll="getScrollAnimate">
    <div class="column">
      <div class="center">
        <img class="micro" src="@/views/Landing/images/big-icon/government_1.svg" alt="" />

        <div class="group-row group-center">
          <h2>Более 200</h2>
        </div>

        <span class="align-center">поставщиков товаров и услуг на нужды вопросов местного значения</span>

        <div class="items-wrap">
          <div class="items marquee reverce">
            <div class="item" @click="openChannel('4')">
              <img src="@/views/Landing/images/company/cinema.svg" alt="" />

              <span>Кинотеатр "СИНЕМА-ЦЕНТР"</span>

              <p>Кино</p>
            </div>

            <div class="item" @click="openChannel('380')">
              <img src="@/views/Landing/images/company/azs.svg" alt="" />

              <span>Туймаада Нефть</span>

              <p>Продажа топлива</p>
            </div>

            <div class="item" @click="openChannel('417')">
              <img src="@/views/Landing/images/company/polar.svg" alt="" />

              <span>Полярные авиалинии</span>

              <p>Авиакомпания</p>
            </div>

            <div class="item" @click="openChannel('474')">
              <img src="@/views/Landing/images/company/megafon.svg" alt="" />

              <span>МегаФон Про Бизнес</span>

              <p>Мобильный оператор</p>
            </div>

            <div class="item" @click="openChannel('535')">
              <img src="@/views/Landing/images/company/bread.svg" alt="" />

              <span>Якутский хлебокомбинат</span>

              <p>Хлебобулочные изделия</p>
            </div>
          </div>

          <div class="items marquee reverce" aria-hidden="true">
            <div class="item" @click="openChannel('4')">
              <img src="@/views/Landing/images/company/cinema.svg" alt="" />

              <span>Кинотеатр "СИНЕМА-ЦЕНТР"</span>

              <p>Кино</p>
            </div>

            <div class="item" @click="openChannel('380')">
              <img src="@/views/Landing/images/company/azs.svg" alt="" />

              <span>Туймаада Нефть</span>

              <p>Продажа топлива</p>
            </div>

            <div class="item" @click="openChannel('417')">
              <img src="@/views/Landing/images/company/polar.svg" alt="" />

              <span>Полярные авиалинии</span>

              <p>Авиакомпания</p>
            </div>

            <div class="item" @click="openChannel('474')">
              <img src="@/views/Landing/images/company/megafon.svg" alt="" />

              <span>МегаФон Про Бизнес</span>

              <p>Мобильный оператор</p>
            </div>

            <div class="item" @click="openChannel('535')">
              <img src="@/views/Landing/images/company/bread.svg" alt="" />

              <span>Якутский хлебокомбинат</span>

              <p>Хлебобулочные изделия</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section trading contact-form" v-animateonscroll="getScrollAnimate">
    <div class="column">
      <div class="center">
        <h2 v-if="showDefault" class="common-form">Появились вопросы?</h2>

        <button v-if="showDefault" @click="openForm" class="common-form">
          Связаться с нами
        </button>

        <div class="callback-form" v-if="showCallbackForm">
          <span>Заполните форму, и мы с Вами свяжемся</span>

          <input class="number"
                 type="text"
                 v-model="name"
                 placeholder="Ваше имя"
          />

          <input class="number"
                 type="text"
                 v-model="organization"
                 placeholder="Организация"
          />

          <input class="number"
                 type="tel"
                 v-maska="'+7 (###) ###-##-##'"
                 v-model="phone_number"
                 placeholder="+7 (000) 000-00-00"
          />

          <label for="iAgree">
            <input class="checkbox"
                   type="checkbox"
                   v-model="iAgree"
                   id="iAgree"
            />
            <span>Я согласен с <a href="https://api.obrashaysya.ru/defaults/docs/privacy_policy.html" target="_blank">политикой обработки персональных данных</a>.</span>
          </label>

          <button @click="send" :disabled="iAgree && (phone_number === '' || phone_number === null) || (name === '' || name === null)">
            Отправить
          </button>
        </div>

        <h2 v-if="callbackSuccess" class="finish-form">Спасибо за отклик!</h2>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>