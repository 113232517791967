import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import inputMask from 'maska'
import 'material-icons/iconfont/material-icons.scss';
import './assets/style.css';
import notifications, {notify} from '@kyvg/vue3-notification';
import velocity from 'velocity-animate';
import vClickOutside from "click-outside-vue3"
import 'cropperjs/dist/cropper.css';
import VIntersection from "@/directives/VIntersection";
import Echo from "laravel-echo";
import io from "socket.io-client";
import {API_URL} from "@/core/constants";
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import AnimateOnScroll from 'primevue/animateonscroll';

import 'floating-vue/dist/style.css';
import FloatingVue from 'floating-vue';

window.$currentDateMessage = '';

const app = createApp(App);

const vuetify = createVuetify({
    components,
    directives
});

app.config.globalProperties.$isShowErrorNotify = true;
app.config.globalProperties.$attachHeader = {};
app.config.globalProperties.$attachHtmlForPdf = {};

startSocket();

app.directive('focus', {
    mounted(element, binding) {
        if (binding.value) {
            element.focus();
        }
    }
})
    .directive('animateonscroll', AnimateOnScroll)
    .directive('intersection', VIntersection);

app.component("VueDatePicker", VueDatePicker);

app.use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(inputMask)
    .use(notifications, {velocity})
    .use(vClickOutside)
    .use(FloatingVue, {
        themes: {
            'custom-theme': {
                $extend: 'tooltip',
                background: '#ffffff',
                color: '#003A5C',
                border: '1px solid #003A5C',
                padding: '8px',
                borderRadius: '4px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }
        }
    })
    .use(vuetify);

app.mount('#app')

export function startSocket() {
    window.io = io;

    window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: API_URL.replace(/\/$/, '') +  ':6001',
        auth: {
            headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
        },
        transports: ['websocket', 'polling', 'flashsocket']
    });

    window.Echo.connector.socket.on('connect', () => {
       console.log('Socket connect');
    });

    window.Echo.private('test-channel').listen('.new-test', function (e){
        console.log(e);
    });
}

export  function startDisclaimer() {
    if (localStorage.disclaimer && localStorage.disclaimer == 'true') {
        const disclaimerDate = new Date(localStorage.disclaimerDate);
        const today = new Date();
        const timeDiff = Math.abs(disclaimerDate.getTime() - today.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (diffDays >= 30) {
            localStorage.disclaimer = 'false';
        }
    } else {
        localStorage.disclaimer = 'false';
    }
}

export function serverFailedAccess() {
    // localStorage.clear();
    // router.push('/authorization');

    if (app.config.globalProperties.$isShowErrorNotify) {
        app.config.globalProperties.$isShowErrorNotify = false;

        notify({
            type: 'error',
            text: 'Сервер временно не доступен, пожалуйста попробуйте позже',
            speed: 1000,
            duration: 5000
        });

        setTimeout(() => {
            app.config.globalProperties.$isShowErrorNotify = true;
        }, 5000);
    }
}

export function serverError(response) {
    if (response.status === 401) {
        localStorage.clear();
        router.push('/authorization');
    }

    if (localStorage.channelId && response.status === 403) {
        // localStorage.clear();
    }

    if (localStorage.channelId || !localStorage.access_token) {
        if (!localStorage.access_token) {
            // localStorage.clear();
            // router.push('/authorization');
        }

        if (app.config.globalProperties.$isShowErrorNotify) {
            app.config.globalProperties.$isShowErrorNotify = false;

            if (response.type === 'cors') {
                response.json().then((response) => {
                    let errorMessage = [];

                    if (response.message) {
                        errorMessage.push(response.message);
                    } else {
                        if (response.errors && Object.keys(response.errors).length !== 0) {
                            Object.values(response.errors).forEach(error => {
                                error.map(text => {
                                    errorMessage.push(text);
                                });
                            });
                        }
                    }

                    notify({
                        type: 'error',
                        text: errorMessage.join('<br>'),
                        speed: 1000,
                        duration: 5000
                    });
                });
            } else {
                let errorMessage = [];

                if (response.message) {
                    errorMessage.push(response.message);
                } else {
                    if (response.errors && Object.keys(response.errors).length !== 0) {
                        Object.values(response.errors).forEach(error => {
                            error.map(text => {
                                errorMessage.push(text);
                            });
                        });
                    }
                }

                notify({
                    type: 'error',
                    text: errorMessage.join('<br>'),
                    speed: 1000,
                    duration: 5000
                });
            }

            setTimeout(() => {
                app.config.globalProperties.$isShowErrorNotify = true;
            }, 5000);
        }
    } else {
        router.push('/channels');

        if (app.config.globalProperties.$isShowErrorNotify) {
            app.config.globalProperties.$isShowErrorNotify = false;

            notify({
                type: 'error',
                text: 'Для продолжения работы необходимо выбрать канал',
                speed: 1000,
                duration: 5000
            });

            setTimeout(() => {
                app.config.globalProperties.$isShowErrorNotify = true;
            }, 5000);
        }
    }
}


