<template>
  <router-view/>
  <notifications
      animation-type="velocity"
      classes="vue-notification notification"
      position="top center"
      width="100%"
  />
</template>

<script>
export default {
  mounted() {
    document.body.addEventListener('focus', (event) => {
      if (event.target.tagName === 'TEXTAREA' || event.target.tagName === 'INPUT') {
        event.target.select();
      }
    }, {capture: true});
  },
  unmounted() {
    document.body.removeEventListener('focus', () => {
    });
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

body {
  background-color: #F7F7F7;
}

.vue-notification-wrapper {
  height: auto !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;

  .vue-notification {
    width: max-content !important;
    font-size: 1rem !important;
  }
}

.notification {
  margin-top: 5px !important;
  font-size: 26px !important;
  cursor: pointer !important;
  border-radius: 15px !important;
  height: auto !important;
  padding: 15px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.legal-document-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  overflow-y: auto;
  height: 100dvh;
  display: block!important;
  text-align: justify;
}

.linkInPost {
  text-decoration: none;
  color: var(--color-blue);

  &:visited {
    color: var(--color-blue);
  }
}

.notClicked {
  pointer-events: none;
}
</style>